.resizableTable {
  //max-height: 700px;
  margin-bottom: 20px;


  .tb-head-th {
    border-bottom: 2px solid $color-neutral;
    font-size: 14px !important;
    .react-resizable {
      margin-right: 0px !important;
      .react-resizable-handle-e {
        top: 14px !important;
        right: -5px !important;
        padding: 0 7px 3px 0 !important;
      }

      .react-resizable-handle {
        background-image: unset !important;
      }

      border-right: none;

      &:hover {
        border-right: 2px solid $color-neutral;
      }
    }
  }

  .tb-10w {
    min-width: 10px !important;
    width: 10px;
  }

  .tb-50w {
    min-width: 50px !important;
    border-bottom: 2px solid $orange-light;
  }

  table {
    width: 100%;
    // border-collapse: collapse;
    border-collapse: separate;
    border-spacing: 0;

    tr {

      &:hover {
        background: #f9f9f9;
      }
    }

    th {
      text-align: start;
      position: sticky;
      top: 0;
      min-width: 120px;
    }

    td {
      word-break: break-word;
      padding: 10px 0;
      cursor: pointer;
      border-bottom: 1px solid $gray;
      font-size: 13px;

      span {
        display: flex;
      }
    }
  }

  .sortWrapper {
    display: flex;
    align-items: center;
    width: max-content;

    .sort {
      & > div {
        display: flex;
        margin-left: 5px;
        flex-direction: column;
        cursor: pointer;
      }

      i {
        color: $gray;
        font-size: 14px;
        position: relative;

        &:first-child {
          top: 3px;
        }

        &:last-child {
          bottom: 3px;
        }
      }
    }
  }
}

.tableList {
  border-collapse: collapse;
  width: 100%;
  margin: 40px 0px !important;

  th,
  td {
    padding: 10px;
    font-size: 1rem;
    text-align: left;
    // min-width: 120px;
  }

  tr,
  thead {
    width: 100%;
  }

  .subRow {
    background-color: $bg-secondary;
    color: $color-bright;

  }

  .rightIcon {
    width: 20px;
  }

  .actions {
    width: 200px;
  }

  tr {
    i {
      color: $color-secondary;
      font-size: medium;
      margin-right: 7px;
      cursor: pointer;

      &:hover {
        color: $color-bright;
      }
    }
  }
}
