.admin-err-msg {
    font-size: x-small;
    color: $red-mono;
    position: absolute;
    right: 0;
    top: 23px;
}

.admin-err-msg-rel {
    font-size: x-small;
    color: $red-mono;
}

.accordion {
    width: 100%;
    margin-bottom: 10px;
    background-color: $bg-secondary;
    padding: 20px 31px;

    .accordionTitle {
        display: flex;
        justify-content: space-between;
        font-size: 1rem;
        cursor: pointer;
        text-transform: capitalize;
        padding: 10px 0;

        p {
            border-bottom: 1.5px solid #63CEA4;
        }

        &::after {
            content: "\276F";
            width: 1rem;
            height: 0.75rem;
            text-align: center;
            transition: all .35s;
            color: $gray;
        }
    }

    .accordionContent {
        display: initial;

        &>div {
            position: relative;
        }
    }

    .accordionExpand {
        position: absolute;
        opacity: 0;
        z-index: -1;

        &:checked {
            +.accordionTitle {

                &::after {
                    transform: rotate(90deg);
                }
            }

            ~.accordionContent {
                max-height: 0;
                transition: all .35s;
                display: none;
            }
        }
    }
}

.accordionCollapse {
    @extend .accordion;

    .accordionTitle {
        &::after {
            transform: rotate(90deg);
        }
    }

    .accordionContent {
        max-height: 0;
        display: none;

        &>div {
            position: relative;
        }
    }

    .accordionExpand {
        position: absolute;
        opacity: 0;
        z-index: -1;

        &:checked {
            +.accordionTitle {

                &::after {
                    transform: rotate(0deg);
                }
            }

            ~.accordionContent {
                //  max-height: 100vh;
                transition: all .35s;
                display: initial;
            }
        }
    }
}

.commentInerHtmlContent {

    [data-denotation-char="#"] {
        color: $pink
    }

    [data-denotation-char="@"] {
        color: $color-bright
    }
}

.pageLoader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    img {
        height: 25px;
    }

    >div {
        padding: 0;
        position: relative;
        top: 5px;
        margin-left: 10px;
    }
}


.infoMsg {
    background: #bae6d4;
    border: 1px solid #68cea4;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
}

.dashboard-chart {
    .react-resizable {
        border-right: none;
        margin-right: 0px;
        padding-right: 0px;
    }


    .react-grid-item > .react-resizable-handle {
        padding-right: 3px;
        &:after {
            content: "";
            position: absolute;
            right: 3px;
            bottom: 3px;
            width: 13px;
            height: 13px;
            border-right: 3px solid $color-bright;
            border-bottom: 3px solid $color-bright;
        }
    }
}
