@import './_styles/rules';

/** Fonts **/
@import "https://p.typekit.net/p.css?s=1&k=aiv7zzz&ht=tk&f=17265.17266.17267.17268.17269.17270.17271.17272.17273.17274.17275.17276&a=22866414&app=typekit&e=css";

@font-face {
	font-family: "source-sans-pro";
	src: url("https://use.typekit.net/af/61f808/00000000000000003b9b3d63/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/61f808/00000000000000003b9b3d63/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/61f808/00000000000000003b9b3d63/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "source-sans-pro";
	src: url("https://use.typekit.net/af/860e41/00000000000000003b9b3d69/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"), url("https://use.typekit.net/af/860e41/00000000000000003b9b3d69/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"), url("https://use.typekit.net/af/860e41/00000000000000003b9b3d69/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: "source-sans-pro";
	src: url("https://use.typekit.net/af/422d60/00000000000000003b9b3d67/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/422d60/00000000000000003b9b3d67/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/422d60/00000000000000003b9b3d67/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: "source-sans-pro";
	src: url("https://use.typekit.net/af/9373a0/00000000000000003b9b3d68/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/9373a0/00000000000000003b9b3d68/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"), url("https://use.typekit.net/af/9373a0/00000000000000003b9b3d68/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: "source-sans-pro";
	src: url("https://use.typekit.net/af/ffb1e2/00000000000000003b9b3d64/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/ffb1e2/00000000000000003b9b3d64/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/ffb1e2/00000000000000003b9b3d64/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: "source-sans-pro";
	src: url("https://use.typekit.net/af/b6c887/00000000000000003b9b3d61/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/b6c887/00000000000000003b9b3d61/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/b6c887/00000000000000003b9b3d61/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: "source-sans-pro";
	src: url("https://use.typekit.net/af/524796/00000000000000003b9b3d62/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff2"), url("https://use.typekit.net/af/524796/00000000000000003b9b3d62/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff"), url("https://use.typekit.net/af/524796/00000000000000003b9b3d62/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: "source-sans-pro";
	src: url("https://use.typekit.net/af/348732/00000000000000003b9b3d65/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/348732/00000000000000003b9b3d65/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/348732/00000000000000003b9b3d65/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: "source-sans-pro";
	src: url("https://use.typekit.net/af/31c386/00000000000000003b9b3d66/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff2"), url("https://use.typekit.net/af/31c386/00000000000000003b9b3d66/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff"), url("https://use.typekit.net/af/31c386/00000000000000003b9b3d66/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: "source-sans-pro";
	src: url("https://use.typekit.net/af/8b9a4a/00000000000000003b9b3d6a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff2"), url("https://use.typekit.net/af/8b9a4a/00000000000000003b9b3d6a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff"), url("https://use.typekit.net/af/8b9a4a/00000000000000003b9b3d6a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 900;
}

@font-face {
	font-family: "source-sans-pro";
	src: url("https://use.typekit.net/af/ca3a3d/00000000000000003b9b3d5f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3") format("woff2"), url("https://use.typekit.net/af/ca3a3d/00000000000000003b9b3d5f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3") format("woff"), url("https://use.typekit.net/af/ca3a3d/00000000000000003b9b3d5f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 200;
}

@font-face {
	font-family: "source-sans-pro";
	src: url("https://use.typekit.net/af/2d1b09/00000000000000003b9b3d60/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3") format("woff2"), url("https://use.typekit.net/af/2d1b09/00000000000000003b9b3d60/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3") format("woff"), url("https://use.typekit.net/af/2d1b09/00000000000000003b9b3d60/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3") format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 200;
}

@import "https://p.typekit.net/p.css?s=1&k=yjc0lpj&ht=tk&f=15882.15883.16448.16449.16452.16453&a=22866414&app=typekit&e=css";
@font-face {
	font-family: "playfair-display";
	src: url("https://use.typekit.net/af/4ae560/00000000000000007735a11a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/4ae560/00000000000000007735a11a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/4ae560/00000000000000007735a11a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "playfair-display";
	src: url("https://use.typekit.net/af/889857/00000000000000007735a126/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/889857/00000000000000007735a126/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/889857/00000000000000007735a126/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: "playfair-display";
	src: url("https://use.typekit.net/af/f0d114/00000000000000007735a11e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/f0d114/00000000000000007735a11e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/f0d114/00000000000000007735a11e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: "playfair-display";
	src: url("https://use.typekit.net/af/8babc2/00000000000000007735a120/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/8babc2/00000000000000007735a120/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"), url("https://use.typekit.net/af/8babc2/00000000000000007735a120/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: "playfair-display";
	src: url("https://use.typekit.net/af/35476c/00000000000000007735a124/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"), url("https://use.typekit.net/af/35476c/00000000000000007735a124/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"), url("https://use.typekit.net/af/35476c/00000000000000007735a124/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: "playfair-display";
	src: url("https://use.typekit.net/af/634fa4/00000000000000007735a127/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff2"), url("https://use.typekit.net/af/634fa4/00000000000000007735a127/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff"), url("https://use.typekit.net/af/634fa4/00000000000000007735a127/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 900;
}

h1 {
	font-family: "playfair-display" !important;
}

button {
	font-family: "source-sans-pro" !important;
}

.sortWrapper {
	font-family: "source-sans-pro" !important;
	font-weight: 600;
}

.resizableTable {
	table {
		tr {
			&:hover {
				background: unset;
			}
		}

		td {
			font-size: 14px;
		}

		.hheader {
			.accordionTitle {
				&:after {
					content: unset;
				}
			}

			.actionButtons {
				display: flex;

				button {
					width: 30px;
					height: 30px;
					margin: 0px 10px;
					border: unset;
					border-radius: 5px;

					img {
						margin: auto;
					}

					&:hover {
						cursor: pointer;
					}
				}
			}

			height: 50px;
		}
	}
}

.nav {
	.navbar_user__7Ez_x {
		font-family: "source-sans-pro";
	}
}

button.button_btnSecondary__N_KzP {
	padding: 21px 30px;
	font-size: 14px;
}

.popUp_wrapper__3Zsy1 {
	h4 {
		font-family: "source-sans-pro";
		color: #000000;
		flex-wrap: wrap;

		span {
			width: 100%;
			position: relative;

			button {
				float: right;
				top: 10px;
				position: relative;
			}
		}
	}
}

.filters_wrapper__303tM {
	background: none;
}

.filters_container__2j7RH {
	background-color: #F9F9F9;
	border-radius: 5px;
	margin-bottom: 10px;
}

button.button_btnGreen__1nXFK {
	height: 44px;
}

.filters_section__2DTfJ {
	.button_btnSecondary__N_KzP {
		&:nth-child(4) {
			color: #7B7B7B;
		}

		&:nth-child(5) {
			background-color: #C9C9C9;
			color: white;
			border: none;
		}
	}
}

.view_container__3LYdq {
	.button_btnActions__3qSw9 {
		height: 32px;
		padding-left: 17px;
		padding-right: 17px;
		font-size: 0.85rem;
	}
}

.view_status__3v-BT {
	.button_btnMutual__os1aA {
		height: 32px;
		padding-left: 17px;
		padding-right: 17px;
		font-size: 0.85rem;
	}
}

p {
	font-family: "source-sans-pro";
}

.accordionTitle {
	p {
		font-weight: bold;
		font-size: 16px;
	}
}

label {
	font-family: "source-sans-pro";
}

.accordion {
	background-color: #F9F9F9;
}

.accordionCollapse {
	background-color: #FBFBFB;
}

.styles_dynamicFieldsWrapper__38Y-c {
	padding-top: 6px;
}

.styles_tabActive__1ponG {
	font-weight: 600;
}


.filter_wrapper__3golb {
	background-color: white;
}

.filter_container__2Ya2M {
	background: #F9F9F9;
	margin-bottom: 20px;
	border-radius: 5px;
}

.filter_options__2DulS {
	.button_btnSecondary__N_KzP {
		font-weight: 600;
	}
}

.modal_modalBody__3gzvI {
	.modal_close__1oBET {
		i {
			color: #7B7B7B;
			font-size: 21px;
			font-weight: 400;
		}
	}
}

h4 {
	font-family: "source-sans-pro" !important;
}

.modal_close__1oBET {
	position: absolute;
	right: 14px;
	top: 13px;
	cursor: pointer;
	z-index: 99999;
}

h5 {
	font-family: "source-sans-pro" !important;
}

.create_wrapper__3VoRJ {
	.create_form__1KLMX {
		.create_select__3upfG {
			label {
				font-weight: 600;
			}
		}
	}
}

.input_inputSmallBold__iTW1G {
	label {
		font-weight: 600;
	}
}

.create_addExisting__zgeJe {
	h5 {
		font-weight: 600;
		font-size: 15px !important;
		margin-bottom: 15px;
		margin-bottom: 0;
	}
}

i.fa.fa-ellipsis-v {
	color: #63CEA4;
	font-size: 14px;
}

.modal_md__3igzN {
	max-width: 480px;
}

.create_modal__1kbmu {
	li {
		font-size: 16px;
	}

	.fa-check-square-o {
		display: none;
	}
}

.userAvatar_wrapper__2Gl1T {
	span {
		font-family: "source-sans-pro";
		font-size: 1.25rem;
		border-bottom: 1px solid #63CEA4;
		font-weight: 600;
	}
}

.myAccount_titleArrowDown__2tAFf {
	p {
		font-family: "source-sans-pro";
		font-size: 1.25rem;
		border-bottom: 1px solid #63CEA4;
		font-weight: 600;
		text-decoration: none !important;
	}
}

.dropdown_wrapper__1T5Nq {
	ul {
		li {
			span {
				padding-left: 16px !important;
				font-size: 1rem !important;
			}

			.dropdown_icon__1qJVq {
				width: 18px !important;
			}
		}
	}
}

nav {
	.navbar_items__1ccH9 {
		.navbar_search__3SNT4 {
			.navbar_list__iwr6Y {
				width: 100%;
				bottom: -25%;
				border-radius: 5px;
				padding: 10px 0px;
				background: #FBFBFB;
			}
		}
	}
}

.searchResults_wrapper__bXN5s {
	.resizableTable {
		table {
			td {
				font-size: 13px;
				padding-left: 15px;
				font-family: "source-sans-pro";
			}


		}
	}
}

.bulk_bulkWrapper__uvRiz {
	padding-left: 5px;
}

.bulk_bulkOptions__G1_3E {
	padding-left: 5px;
}

.expande-menu {
	svg {
		height: 14px;
		margin-bottom: 15px;
	}

	cursor: pointer;
}

.expandeon {
	background: #ffffff;
	height: 100%;
	box-shadow: 2px 0px 6px #00000029;
	z-index: 1;
	position: fixed;
}

.leftSidebar_wrapper__1TeA4 {
	.leftSidebar_parent__3TSn1 {
		span {
			padding: 0 13px 0 23px;
		}
	}
}

.notificationList_wrapper__3XBy9 {
	width: 60%;
	display: block;
	margin: auto;
}

.notificationList_list__1PWfR {
	.notificationList_readNotification__3I_O6 {
		border: none !important;
	}
}

.notifications_container__36UYz {
	.notificationList_list__1PWfR {
		.notificationList_readNotification__3I_O6 {
			img {
				width: 60px;
				height: 60px;
				border-radius: 30px;
			}

			background: #FBFBFB !important;

			h6 {
				font-size: 19px !important;
				font-family: "source-sans-pro";
				width: 100%;
			}

			.notificationList_body__J8smv {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
			}

			small {
				font-size: 14px !important;
				margin-right: 2%;
			}

			span {
				font-size: 13px !important;
			}
		}
	}
}

.content.Notifications {
	.title_title__tr28g {
		display: block;
		text-align: center;
		border-bottom: none;
		margin-bottom: 24px;
		margin-top: 30px;
	}
}

.Automation {
	.automation_imgWrapper__3pJ89 {
		display: revert !important;
	}
}

.Reminders {
	.reminders_imgWrapper__UI9Y9 {
		display: revert !important;
	}
}

.wrapperChat {
	.leftIcons {
		display: grid;
		width: 97%;
		max-width: 97%;
		grid-template-columns: repeat(auto-fill, minmax(13%, 2fr));
	}
}

#reactQuill {
	//width: 450px !important;
	margin-bottom: 20px !important;
	height: auto;

	.quill {
		//width: 450px !important;
		height: auto;
	}
}

.Reports {
	.reports_resizableTable__1VJmd {
		tr {
			justify-content: space-between;
			border-bottom: 1px solid #979797;
		}

		td {
			border-bottom: none !important;
		}
	}
}

.reports_reportRow__2UDpI {
	border-bottom: 2px solid #E4C6C1 !important;

	th {
		border-bottom: none !important;
	}
}

@media only screen and (min-width: 768px) {
	.modal_modalBody__3gzvI {
		padding: 46px 38px 40px;
	}
}

@media only screen and (min-width: 992px) {
	.dropdown_wrapper__1T5Nq ul li {

		height: 55px !important;

	}


	.dropdown_wrapper__1T5Nq {
		top: 43px !important;
		width: 200px !important;
		right: -18px !important;

		ul {
			li {
				a {
					padding: 0px 12px !important;
				}

				.dropdown_logout__1vA8M {
					padding: 0px 27px !important;
				}
			}
		}
	}
}


.widget_widgets__yJCLN .widget_widget__-eyR0 .widget_items__2on_k h3 {
	margin-top: 0;
}


.searchDropdown_wrapper__2pQZX .searchDropdown_container__24QMn {
	overflow-y: scroll !important;
	height: 50vh !important;
	min-width: 536px !important;
}

.modal_customModal__1tx_Z {
	width: unset !important;
	top: unset !important;
}

.ReactModal__Content.ReactModal__Content--after-open.modal_customModal__1tx_Z.modal_xxlg__BjNBp {
	width: 65% !important;
}

p.styles_tabBtnsActive__18Jvn {
	color: black !important;
	font-weight: 600 !important;
	font: normal normal bold 14px/20px source-sans-pro !important;
}


.comment_wrapper__3k0nf .comment_discusionTitle__2ZHd1 input {
	font-family: "SourceSans-SemiBold";
	font-size: 0.875rem !important;
	border: none;
	padding: 10px 7px !important;
	font: normal normal normal 14px/20px source-sans-pro !important;
	letter-spacing: 0px !important;
	color: #BBBBBB !important;
}

.comment_discusionTitle__2ZHd1 img {
	position: relative !important;
	top: 4px !important;
}


.comments_wrapper__41f3n .comments_item__3_vrm {
	background: #FFFFFF !important;
}

.comment_wrapper__3k0nf .comment_commnetWrapper__2StD1 .comment_message__2wNG5 p, .comment_wrapper__3k0nf .comment_commnetWrapper__2StD1 .comment_message__2wNG5 div {
	font-size: 0.75rem;
	font: normal normal normal 12px/17px source-sans-pro;
}

.comment_wrapper__3k0nf .comment_commnetWrapper__2StD1 .comment_content__1DPTU {
	border-bottom: none !important;
}

.comments_wrapper__41f3n .comments_item__3_vrm {
	border-bottom: 0.5px solid #707070;
}

.comment_wrapper__3k0nf .comment_commnetWrapper__2StD1 .comment_header__3TppQ small {
	padding-left: 50px;

	font: normal normal normal 10px/14px source-sans-pro !important;
}

.comments_wrapper__41f3n .comments_reply__TGCNV h6 {
	background: #F9F9F9 !important;
}


.comments_wrapper__41f3n .comments_reply__TGCNV h6 {

	padding: 8px 13px !important;

	font: normal normal bold 12px/17px source-sans-pro !important;
	letter-spacing: 0px;
	color: #7B7B7B !important;
	margin-bottom: 10px;
	border-radius: 5px !important;
}

.wrapperChat .leftIcons {
	padding: 12px !important;
}

.wrapperChat .quill {
	border-top: none !important;
	height: unset !important;
}

.react-switch-handle {
	top: 1px !important;
}

.switchEditor h5 {
	font-weight: 400;
}

.quilCustom #toolbarCustom .rightIcons button {

	font-size: 13px !important;
	right: 20px !important;
	position: relative !important;
}

.filters_wrapper__2ipIN {
	background: #ffffff !important;
}

.filters_container__3uXAd {
	background: #F9F9F9 !important;
	margin-bottom: 10px;
}

@media only screen and (min-width: 1200px) {
	nav {
		padding: 0 60px;
	}

}

@media only screen and (min-width: 992px) {
	nav .navbar_items__1PWnT {
		height: 60px;
	}
}


@media only screen and (min-width: 992px) {

	.page-content-sidebar {
		padding-top: 60px !important;
	}

}

.search_navSearch__3GbEx {
	height: 35px !important;
	border-radius: 5px;
}


@media (min-width: 992px) {

	.dropdown_wrapper__nc4QY {
		margin-top: 0px;
		background: #ffffff;
		align-items: flex-start;
		border-radius: 0px 0px 5px 5px;
		z-index: 9;
		position: absolute;
		top: 43px !important;
		width: 200px !important;
		right: -18px !important;


	}

}


// @media only screen and (min-width: 992px){
// .dropdown_wrapper__nc4QY ul li a, .dropdown_wrapper__nc4QY ul li .dropdown_logout__3nkKj {
//     padding: 0px 12px !important;
// }

// }


.dropdown_wrapper__nc4QY ul li span {
	width: calc(100% - 20px);
	font-size: 0.8rem !important;

}

.dropdown_wrapper__nc4QY ul li .dropdown_icon__2Jufi {
	width: 17px !important;

}


@media only screen and (min-width: 992px) {

	.dropdown_wrapper__nc4QY ul li {
		display: flex !important;
		align-items: center !important;
		height: 59px !important;
		border-bottom: 1px solid #979797;
	}

}

.table_wrapper__226hX {
	position: relative;
}

.List .popUp_popupWrapper__N6vom {
	position: absolute !important;
	right: 0 !important;
	top: 0 !important;
}

.List .popUp_wrapper__2oPVw {
	right: 0 !important;
	left: unset !important;
	top: 0 !important;
}

.List .tb-head-th .popUp_popupWrapper__N6vom {
	display: none;
}

h3 {
	margin-top: 0 !important;
}

.filters_wrapper__1b3TS .filters_content__rWO0c .filters_containerWrapper__3MQCt p {
	text-align: left !important;
}

.main-ul {
	overflow: visible;
	height: inherit !important;
	padding-bottom: 20px !important;
}

.styles_tabActive__1e7OB {

	font: normal normal bold 14px/20px source-sans-pro;

}

.styles_tab__2l8AG {
	font: normal normal normal 14px/20px source-sans-pro;
}


@media only screen and (min-width: 992px) {

}


.input_inputDynamic__30ZT1 {
	border-bottom: 1px solid #707070 !important;
}

.addReminder_wrapper__3ruuF .addReminder_form__QcvOf > div {

	padding: 7px 2px !important;
}

.button_btnRound__3sGyv, .button_btnRoundBorder__1U6u- {
	border-radius: 5px !important;
}

.addReminder_wrapper__3ruuF .addReminder_newRow__2Ul82 {
	padding-top: 20px !important;
}


@media only screen and (min-width: 992px) {
	nav .navbar_items__1ccH9 {
		height: 57px;
	}
}



