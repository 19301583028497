* {
  box-sizing: border-box;
}

body,
html {
  font-family: $font-primary;
  height: 100%;
  margin: 0;
  color: $color-primary;
  box-sizing: border-box;
  font-size: 14px;
  background: $bg-primary;
  overflow-x: hidden;
}

a {
  color: $link;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  :active {
    color: $link-hover;
    text-decoration: none;
  }
}

ul {
  list-style-type: none;
  padding-inline-start: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p {
  margin: 0;
}

nav {
  z-index: 100 !important;
}

@media print {
  body * {
    visibility: hidden;
  }

  nav {
    display: none;
  }

  .page-content-sidebar {
    &>section:first-child {
      display: none;
      width: 220px;
      padding: 0;
    }

    &>section:last-child {
      width: 100%;
      margin-left: 0;
      padding: 0;
    }

  }

  .main-wrapper,
  .main-wrapper * {
    visibility: visible;
  }

  .main-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    //height: 100vh;
    z-index: 9999;
  }

}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {

  body,
  html {
    font-size: 14px;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {}

@media print {

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
    break-inside: avoid;
  }
}

@page {
  size: auto;
  margin: 20mm;
  break-inside: avoid;
}
