#root,
.App,
.main-wrapper {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  background: $bg-primary;
  height: 100%;
}

.page-content-public,
.page-content-private,
.page-content-sidebar {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}

.page-content-public {}

.page-content-private {
  padding-top: 50px;

  .content {
    padding: 30px 50px;

  }

  .StepOne, .StepTwo {
    padding: 0;
    margin-top: -80px;
  }

  @media only screen and (min-width: 992px) {
    padding-top: 80px;
  }
}

.page-content-sidebar {
  align-items: start;
  padding-top: 50px;
  height: 100%;
  section{
    >div{
      height: 100%;
    }
  }
  .content {
    width: calc(100% - 50px);
    padding: 30px 30px;
    min-width: 1000px;
    height: 100%;

    &.subroute {
      width: 100%;
      margin-left:0;
      padding: 0;
    }
  }

  @media only screen and (min-width: 992px) {
    padding-top: 80px;

    .content {
      padding: 60px 30px 30px 30px;
      overflow: visible;

      &.subroute {
        width: 100%;
        margin-left:0;
        padding: 0;
      }
    }
  }
}

.content {
  width: 100%;

}

.container-wrapper {
  padding: 0 24px;
  margin: 0 auto;
  height: 100%;
  width: 100%;
}

.container-fluid-wrapper {
  width: 100%;
  padding: 0px;
  margin: 0 auto;
}

@media screen and (min-width:768px) {
  .container-wrapper {
    padding: 0 30px;
  }
}

@media screen and (min-width:1200px) {
  .container-wrapper {
    padding: 0px;
    max-width: 1160px;
  }
}
