$white: #ffffff;
$black: #000000;
$green-light:#63CEA4;
$gray :#979797;
$silver: #BBBBBB;
$gray-light: #F9F9F9;
$orange-light:#E4C6C1;
$red-mono:#D16B5D;
$pink:#E86BA1;
$downy-blue: #63BFCE;
$caribbean-green: #00DAA7;
$semi-gray: #bbbbbb;
$alabaster: #FBFBFB;
$downy: #63cea463;
$dove-gray: #707070;
$washed-pink: #EFDAD7;
$red-urgent: #cd1800;


$bg-primary: $white;
$bg-secondary: $gray-light;

$color-primary: $black;
$color-secondary: $gray;
$color-tertiary: $washed-pink;
$color-light: $white;
$color-bright: $green-light;
$color-neutral:$orange-light;

$btn-light: $pink;
$btn-social: $red-mono;

$link: $black;
$link-hover: $green-light;
$link-active: $green-light;
$link-primary: $green-light;
$link-secondary: $downy-blue;

$highlight-color: $downy;

$font-primary: "SourceSans-Regular";
$font-primary-semiBold: "SourceSans-SemiBold";
$font-primary-bold: "SourceSans-Bold";
$font-secondary: "Roboto-regular";
$font-secondary-medium: "Roboto-medium";
$font-secondary-bold: "Roboto-bold";
